$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(".js-navbutton").toggleClass("active");
        $(".js-nav").toggleClass("active");
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * heroSlider
    // *
    // *
    var $heroSlider = $(".js-hero-slider");

    if ($heroSlider.length) {
        $heroSlider.each(function () {
            var slider = $(this).find(".js-hero-slider-track");
            
            slider.slick({
                fade: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-hero-slider-dots"),
                prevArrow: $(this).find(".js-hero-slider-prev"),
                nextArrow: $(this).find(".js-hero-slider-next"),
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * imageSlider
    // *
    // *
    var $imageSlider = $(".js-image-slider");

    if ($imageSlider.length) {
        $imageSlider.each(function () {
            var slider = $(this).find(".js-image-slider-track");
            
            slider.slick({
                fade: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-image-slider-dots"),
                prevArrow: $(this).find(".js-image-slider-prev"),
                nextArrow: $(this).find(".js-image-slider-next"),
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * quoteSlider
    // *
    // *
    var $quoteSlider = $(".js-quote-slider");

    if ($quoteSlider.length) {
        $quoteSlider.each(function () {
            var slider = $(this).find(".js-quote-slider-track");

            slider.slick({
                fade: true,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 1000,
                waitForAnimate: true,
                adaptiveHeight: true,
                dots: false,
                pauseOnHover: false,
                prevArrow: $(this).find(".js-quote-slider-prev"),
                nextArrow: $(this).find(".js-quote-slider-next"),
            });


            $(this).find('.js-quote-slider-prev, .js-quote-slider-next').on('click', function () {
                slider.slick("slickPause");
            });
        });


    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * contentSlider
    // *
    // *
    var $contentSlider = $(".js-content-slider");

    if ($contentSlider.length) {
        $contentSlider.each(function () {
             var slider = $(this).find(".js-content-slider-track");
            
            slider.slick({
                fade: false,
                infinite: true,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 2500,
                speed: 1000,
                waitForAnimate: true,
                dots: true,
                pauseOnHover: false,
                appendDots: $(this).find(".js-content-slider-dots"),
                prevArrow: $(this).find(".js-content-slider-prev"),
                nextArrow: $(this).find(".js-content-slider-next"),
            });
        });
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-item").click(function () {
        $(this).parents(".js-accordion-wrapper").find(".js-accordion-item").not(this).removeClass("active open");

        if ($(this).hasClass("open")) {
            $(this).removeClass("open")
        }
        else {
            $(this).toggleClass("active");
        }
        // when gsap site dont scroll into view
        if ($(".js-smooth-content").length == 0) {
            setTimeout(() => {
                $(this).get(0).scrollIntoView({ behavior: 'smooth' })
            }, 400);
        }
    });

    // open accordion with anchor link
    let hash = window.location.hash.split('#')[1];
    if ($("#" + hash).hasClass("js-accordion-item") && !$("#" + hash).hasClass("open")) {
        $("#" + hash).click();
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * dialog window
    // *
    // *
    var $dialog = $(".js-dialog"),
        dialogState = sessionStorage.getItem('dialogState');

    $(".js-close-dialog").click(function () {
        $dialog.addClass("close");
        sessionStorage.setItem('dialogState', true);
        $(this).parents(".js-dialog").get(0).close();
        $("body").removeClass("freeze");
    });

    // backdrop click
    $(".js-dialog").click(function (e) {
        if (e.target === e.currentTarget) {
            $dialog.addClass("close");
            sessionStorage.setItem('dialogState', true);
            $(this).get(0).close();
            $("body").removeClass("freeze");
        }
    });

    if (!dialogState && $dialog.length > 0) {
        $dialog.get(0).showModal();
        $("body").addClass("freeze");
    }

});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }
});
